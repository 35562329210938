/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Alpine.start();
window.Alpine = Alpine;

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import VhCalc from '@js/helpers/ViewHeightCalc';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import Navigation from '@js/modules/Navigation';
import NavDropDown from "@js/modules/NavDropDown";
import Page from '@js/modules/Page';
import ScrollTo from '@js/modules/ScrollTo';

window.WHISKYCOLLECTION =
    window.WHISKYCOLLECTION ||
    new (function WHISKYCOLLECTION() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            VhCalc: VhCalc,
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Appear: Appear,
            AppearParent: AppearParent,
            Navigation: Navigation,
            NavDropDown: NavDropDown,
            Page: Page,
            ScrollTo: ScrollTo,
        };


    })();




