import env from '@js/utils/env';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import DelayedCall from '@js/helpers/DelayedCall';

class Appear {
    constructor() {
        this.appearTargets = [];
    }
    start() {
        this._setup();
    }
    clear() {
        this.appearTargets.forEach((target) => {
			PageScrollingManager.unwatch(target);
		});
    }
    _setup() {
        const attrName = 'data-appear';

        // Appear elements
		const appearElements = document.querySelectorAll('[' + attrName + ']');

        // Callback for IntersectionObserver
        const appearCallback = function(entries) {
            entries.forEach(entry => {
                const attributeValues = entry.target
    				.getAttribute(attrName)
    				.split(',');

                // Get the default delay duration
                const delay = Number.parseFloat(attributeValues[0] || 0);
                // Get the mobile delay duration
                const delayMobile = Number.parseFloat(attributeValues[1] || 0);
                // Get the class to add to the element
    			const addClass = attributeValues[2] || '_visible';

                // Is the element in the viewport?
                if (entry.isIntersecting) {
                    let delayedCallDelay = delay;

                    // If mobile value has been pased and it is a mobile device get mobile delay
                    if (delayMobile > 0 && env.isMobile) {
                        delayedCallDelay = delayMobile;
                    }

                    DelayedCall.create(delayedCallDelay, () => {
						entry.target.classList.add(addClass);

                        appearObserver.unobserve(entry.target);
					});
                }
            });
        };

        const appearObserver = new IntersectionObserver(appearCallback);
		appearElements.forEach((target) => {
            // Add the element to the watcher
            appearObserver.observe(target);

            // const attributeValues = entry.target
            //     .getAttribute(attrName)
            //     .split(',');

            // const delay = Number.parseFloat(attributeValues[0] || 0);
            // const addClass = attributeValues[1] || '_visible';

			// const delay = Number.parseFloat(attributeValues[0] || 0);
			// const addClass = attributeValues[1] || '_visible';
            //
			// this.appearTargets.push(
			// 	PageScrollingManager.watchOnce(element, () => {
			// 		DelayedCall.create(delay, () => {
			// 			element.classList.add(addClass);
			// 		});
			// 	})
			// );
		});
    }
}

export default new Appear();
