const CSS_OPENED = '_open';
const CSS_CLOSED = 'hidden';

class NavDropDown {
    constructor() {
        document.querySelectorAll('[data-nav-dropdown]').forEach((el) => {
            this._init(el);
        });
    }
    _init(element) {
        const parent = element.parentNode,
            subnav = parent.querySelector('[data-nav-dropdown-subnav]');

        let opened = false,
            animating = false;

        element.addEventListener('click', (e) => {
            if (!animating) {
                opened = element.classList.contains(CSS_OPENED);

                animating = true;

                if (!opened) {
                    element.classList.add(CSS_OPENED);
                    subnav.classList.remove(CSS_CLOSED);

                    // WCAG
                    element.setAttribute('aria-expanded', true);

                    let h = subnav.offsetHeight;
                    h += parseInt(window.getComputedStyle(subnav).getPropertyValue('margin-top'));
                    h += parseInt(window.getComputedStyle(subnav).getPropertyValue('margin-bottom'));
                } else {
                    element.classList.remove(CSS_OPENED);
                }
            }
        });
    }
}

export default new NavDropDown();
