import PageScrollingManager from "./PageScrollingManager";
import PageScrolling from "./PageScrolling";

const HTML_CLASSLIST = document.documentElement.classList;
const CSS_OPENED = '_nav-open';

class Navigation {
    constructor() {
        this.opened = false;

        // Nav button
        this.opener = document.querySelector('[data-mobile-nav-btn]');
        if (this.opener) {
            this.opener.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggle();
            });
        }

        // Close button
        this.closer = document.querySelector('[data-mobile-nav-close-btn]');
        if (this.closer) {
            this.closer.addEventListener('click', (e) => {
                e.preventDefault();
                this.close();
            });
        }

        // Overlay
        this.overlay = document.querySelector('[data-mobile-nav-overlay]');
        if (this.overlay) {
            this.overlay.addEventListener('click', (e) => {
                e.preventDefault();
                this.close();
            });
        }

        window.addEventListener('keydown', (e) => {
            if (this.opened && e.keyCode == 27) {
                this.close();
            }
        });

        window.addEventListener('resize', e => {
            this.close();
        });
    }
    open() {
        if (!this.opened) {
            this.opened = true;

            HTML_CLASSLIST.add(CSS_OPENED);

            PageScrolling.lock(document.querySelector('.mn-nav '));
        }
    }
    close() {
        if (this.opened) {
            this.opened = false;

            HTML_CLASSLIST.remove(CSS_OPENED);

            PageScrolling.unlock(document.querySelector('.mn-nav '));
        }
    }
    toggle() {
        if (this.opened) {
            this.close();
        }
        else {
            this.open();
        }
    }
}

export default new Navigation();
