// Imports
import Chart from 'chart.js/auto';
import LazyLoad from "vanilla-lazyload";

class Page {
    constructor() {
        this.init();
    }
    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.domLoaded();
        });

        window.addEventListener('load', () => {
            this.windowLoaded();
        });
    }
    loadAll() {
        this.domLoaded();
        this.windowLoaded();
    }
    domLoaded() {
        /**
         * Lazy load
         */
        window.lazyLoadInstance = new LazyLoad({
            threshold: 400
        });

        /**
         * Search
         */
        const searchBtnElements = document.querySelectorAll('[data-search-btn]');
        const searchFormOverlayElement = document.getElementById('search-overlay');
        const searchOverlayCloseElements = document.querySelectorAll('[data-search-overlay-close]');

        searchBtnElements.forEach((element) => {
            element.addEventListener('click', (e) => {
                e.preventDefault();

                // Test of the overlay is in the DOM
                if (searchFormOverlayElement) {
                    // Show the overlay
                    searchFormOverlayElement.classList.remove('hidden');

                    // Focus on the search input
                    document.getElementById('search-form-input').focus();
                }
            });
        });

        searchOverlayCloseElements.forEach((element) => {
            element.addEventListener('click', (e) => {
                e.preventDefault();

                // Test of the overlay is in the DOM
                if (searchFormOverlayElement) {
                    // Hide the overlay
                    searchFormOverlayElement.classList.add('hidden');
                }
            });
        });

        const locationChartEl = document.getElementById('location-chart');
        if (locationChartEl) {
            const locationChart = locationChartEl.getContext('2d');

            const chartLabels = JSON.parse(locationChartEl.getAttribute('data-labels'));
            const chartData = JSON.parse(locationChartEl.getAttribute('data-percentages'));
            const chartColours = JSON.parse(locationChartEl.getAttribute('data-colours'));

            console.log({chartLabels});
            console.log({chartData});

            let myChart = new Chart(locationChart, {
                type: 'pie',
                data: {
                    labels: chartLabels,
                    datasets: [{
                        label: 'Whisky locations',
                        data: chartData,
                        //backgroundColor: chartColours
                    }]
                }
            });
        }
    }

    windowLoaded() {

    }
}

export default new Page();

